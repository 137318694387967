import { exitLabels } from 'AcademyOld/constants';
import { isDark } from 'AcademyOld/helpers';

const displayRoom = (output, forceDisplayRoomDesc, gameState, gameData) => {
  let exitsText = '';
  const x = gameState?.currentRoom;
  if (isDark(gameState, gameData)) {
    return `${output}\nIt is too dark. I cannot see.\n`;
  }
  for (let d = 1; d <= 6; d++) {
    if (gameData.rooms[x].exits[d] > 0) {
      exitsText = `${exitsText}${exitsText.length > 0 ? ', ' : ''}${
        exitLabels[d]
      }`;
    }
  }
  const roomName = gameData.rooms[x].name;
  if (roomName.charAt(0) === '*') {
    output = `${output}${roomName.replace('*', '')}\n`;
  } else {
    output = `${output}${roomName}\n\n`;
    output = `${output}${gameData.rooms[gameState.currentRoom].desc}\n\n`;
  }
  // if (!gameData.rooms[gameState.currentRoom].links != null) {
  //   output = `${output}Links: ${gameData.rooms[gameState.currentRoom].links}\n`;
  // }
  return `${output}\n\n\n`;
};

export default displayRoom;
