import React, { useState } from 'react'

function ChatInput({ onSubmitMessage }) {
  const [message, setMessage] = useState('')

  return (
    <form
      action="."
      onSubmit={e => {
        e.preventDefault()
        onSubmitMessage(message)
        setMessage('')
      }}
    >
      <input
        type="text"
        value={message}
        onChange={e => setMessage(e.target.value) }
      />
      {/* <input type="submit" value={'Send'} /> */}
    </form>
  )
}

export default ChatInput
