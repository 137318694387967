import PropTypes from 'prop-types';

const CassetteHub = ({ pos }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33.66 33.66"
    className={`cassette-hub cassette-hub-${pos}`}
  >
    <path
      d="M412.14,300.77a16.82,16.82,0,1,0-1,12.84A16.74,16.74,0,0,0,412.14,300.77Zm-4.57,11a12.57,12.57,0,0,1-1.76,2.59l-1.92-2.65a2.88,2.88,0,0,0-4.66,3.39l1.92,2.65c-.34.15-.69.28-1,.4h0a12.74,12.74,0,0,1-9-.4l1.92-2.65a2.89,2.89,0,0,0-4.67-3.39l-1.93,2.66a12.86,12.86,0,0,1-2.51-4.45,12.65,12.65,0,0,1-.58-5.09l3.12,1a2.89,2.89,0,0,0,3.63-1.85h0a2.89,2.89,0,0,0-1.85-3.64l-3.11-1a12.71,12.71,0,0,1,7-5.59,10.87,10.87,0,0,1,1.08-.3v3.28a2.89,2.89,0,0,0,5.77,0v-3.28a12.85,12.85,0,0,1,8.1,5.89l-3.11,1a2.89,2.89,0,1,0,1.78,5.49l3.11-1A12.7,12.7,0,0,1,407.57,311.79Z"
      transform="translate(-379.31 -289.14)"
      fill="#414042"
    />
  </svg>
);

CassetteHub.propTypes = {
  pos: PropTypes.string,
};

export default CassetteHub;
