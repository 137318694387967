import React from 'react';
import PropTypes from 'prop-types';

// Use this button to enable actions on tutorial
const ActionButton = ({action, route}) => 
{
    const handleAction = (e) => {
        window.open(action, '_blank').focus();
      };
    
    if (action != null) {
        return (
            <button className ="commandButton" onClick={handleAction}>{route}</button>
        );
    }
    return (
        <button className ="commandButton">No Action</button>
    );
    
}

ActionButton.propTypes = {
    action: PropTypes.string,
    route: PropTypes.string,
};

export default ActionButton;
