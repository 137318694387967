import { PageMain } from './components';
import './styles.scss';

function AcademyOld() {
  return (
    <div className="App">
      <PageMain />
    </div>
  );
}

export default AcademyOld;