import React from 'react';
import PropTypes from 'prop-types';

const TerminalPicture = ({source}) => (
  <div className="terminalOutputWrap">
    <div className="terminalPictureWrap">
      <img className="terminalPicture" src={source}></img>
    </div>
  </div>
);

{TerminalPicture.propTypes = {
  source: PropTypes.string,
};}

export default TerminalPicture;