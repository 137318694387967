import React from 'react'
import Nav from '../Nav/Nav';

function Intro() {
  return (
    <div className="Intro">
      <Nav />
    </div>
  )
}

export default Intro