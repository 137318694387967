import React from 'react'
import './styles.scss';
import Chat from '../Chat/Chat'
import Nav from '../Nav/Nav'

function Home() {
  return (
    <div className="Home">
      <Nav />
      <Chat />
    </div>
  )
}

export default Home
