const displayHelp = (updateStdOutput) => {
  const output = `> ?
HELP:
- Type "SAVE GAME" to save the tutorial and "LOAD GAME" to load it. You can also use save slot #s such as "SAVE GAME 1" and "LOAD GAME 1."
- If you want to select another tutorial, use "QUIT."
`;
  updateStdOutput(output, 'append');
  return null;
};
export default displayHelp;
