import React from 'react'
import { Link } from 'react-router-dom';
import './styles.scss';

function Nav() {
  return (
    <div className='navbar'>
      <Link to="/">Home</Link>
      <Link to="/intro">Intro</Link>
      <Link to="/lore">Lore</Link>
      <Link to="/academyold">AcademyOld</Link>
    </div>
  );
}

export default Nav;
