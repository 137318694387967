import React from 'react'
import Chapter from './chap1';
import './styles.scss';
import Nav from '../Nav/Nav';

function Lore() {
  return (
    <div className="Lore">
      <Nav />
      <Chapter />
   </div>
  )
}
export default Lore