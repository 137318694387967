import React, { useMemo } from 'react'
// import { SessionProvider } from "next-auth/react";
import { ConnectionProvider, WalletProvider, } from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { CoinbaseWalletAdapter, PhantomWalletAdapter, SlopeWalletAdapter,SolflareWalletAdapter, TorusWalletAdapter, BackpackWalletAdapter } from
"@solana/wallet-adapter-wallets";
// import type { AppProps } from "next/app";
require('@solana/wallet-adapter-react-ui/styles.css');

export const Wallet = ({ children }) => {
  const endpoint = "https://api.devnet.solana.com"; // Dev
  // const endpoint = "https://solana-mainnet.g.alchemy.com/v2/CyPuvASbsKLFevzpBoQyeeGm6SPv07P0" // Prod
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new SlopeWalletAdapter(),
      new BackpackWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          {children}
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
