import { useEffect, useRef } from 'react';
import { gsap, Expo } from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { useRandomReveal } from 'react-random-reveal';
import Lenis from '@studio-freight/lenis';
import './styles.scss';

function Chapter(props) {
  // Lenis smooth scrolling
  let lenis;

  // Initialize Lenis smooth scrolling
  const initSmoothScrolling = () => {
    lenis = new Lenis({
      lerp: 0.2,
      smooth: true
    });

    lenis.on('scroll', () => ScrollTrigger.update());

    const scrollFn = (time) => {
      lenis.raf(time);
      requestAnimationFrame(scrollFn);
    };

    requestAnimationFrame(scrollFn);
  };

  gsap.registerPlugin(ScrollTrigger);
  initSmoothScrolling();

  const animateEaseIn = (className) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: className,
        scrub: true,
        start: 'top 95%',
        end: 'bottom 40%',
        markers: false
      }
    })
    .fromTo(className,
      {
        x: 300,
        autoAlpha: 0
      },
      {
        x: 0,
        autoAlpha: 1,
        duration: 3,
        stagger: 2,
        ease: Expo.out
      }
    );
  }

  useEffect(() => {
    animateEaseIn('.trigger');
    animateEaseIn('.trigger2');
  }, []);

  const ChapterTitle = () => {
    const characters = useRandomReveal({
      isPlaying: true,
      duration: 3,
      characters: 'Chapter 1: History before Proof of History',
    })
    return characters
  }

  return (
    <>
      <div className="content__hero">
        <h1 className="intro__title">
          <span className="intro__title-pre">Solana Lore</span>
        </h1>
        <h2 className="intro__title">
          <span className='intro__title-sub'>
            <ChapterTitle></ChapterTitle>
          </span>
        </h2>
      </div>
      <div className="content">
        <h2 className="content__title content__title--left">
          <p className="font-small">You would have spent a long time searching for the era-changing innovation or social paradigm shift for which crypto would later be known for. Before the new financial systems and mass digital communities, there was more dream than substance, a new world of untold riches, secrets, and freedom.</p>
          <p className="font-small">A new world it was, and its riches is what made it ripe to be conquered. Ready to make their mark or seize what was thought to be theirs, the smartest technologists, savviest hustlers, and robust financial minds eagerly flocked to the space. If you were a digital pioneer coming to the crypto in 2015, it would be hard to make sense of what was real and what was disillusion. It was tiny community of individuals that materialized value for digital assets that gave more of the impression of magic than mathematics.</p>
          <p className="font-small">As in any world that starts to show financial promise, crypto attracted more eyes. “The Wild West” it was dubbed, and like its moniker, attracted a variety of characters to it. The massive influx of new participants driven by curiosity and greed marked 2017 as a year frothing with aspiration and innovation. In that year, Bitcoin was becoming a household name connected to riches, while Ethereum opened up a financial sandbox to the world. The applications of crypto and blockchain seemed endless, money flowed freely, and projects were launched. “A token for x”. “the decentralized y”.</p>
        </h2>
      </div>
      <div className='content trigger'>
        <h2 className="content__title">
          <span className="font-13 font-medium font-height-medium">
            Like any important era of history, idols emerged and created tribalism in the crypto world.
          </span>
        </h2>
      </div>
      <div className="content">
        <h2 className="content__title content__title--left">
          <p className="font-small">The young genius Vitalik Buterin of Ethereum. The anonymous Satoshi Nakamoto of Bitcoin. The rising giant CZ of Binance. The upstart Justin Sun of Tron. Ex-Ethereum Charles Hoskinson of Cardano. The ostentatious Richard Heart of Hex. Rarely was anyone not part of a tribe, each a zealot in a bird themed townsquare.</p>
          <p className="font-small">However, in any other digital world these would be simply tribes with different ideologies and technologies. These tribes are countries, their crypto their currency.</p>
          <p className="font-small">The two top technologies, Bitcoin and Ethereum, carried the majority of the weight of this new world's dreams, building a digital native ecosystem that required no centralized authority. Proof of Work, the consensus model that powered both successfully, exhibited drawbacks that prevented these from scaling the tiny crypto world. New technologies and tribes arose to challenge these two, but none became close to threatening them as a new technology that began to form. </p>
          <p className="font-small">A future quote to one particular technology:</p>
        </h2>
      </div>
      <div className="content trigger2">
        <h2 className="content__title">
          <span className="font-13 font-medium font-height-medium">
            “You don't need to think twice about doing anything because it moves so quickly and costs so little. That's the point. It feels like using the internet.”
            <br />
            -Packy McCormick
          </span>
        </h2>
      </div>
      <div className="intro"><p><a class="line" href="chapter2.html">Chapter2</a></p></div>
    </>
  );
}
export default Chapter;