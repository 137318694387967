import React, { useMemo } from 'react'
import AcademyOld from './AcademyOld/AcademyOld'
import { Wallet } from './helpers/Wallet'
import Home from './Home/Home'
import Intro from './Intro/Intro'
import Lore from './Lore/Lore'
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Wallet>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="intro" element={<Intro />} />
          <Route path="lore" element={<Lore />} />
          <Route path="academyold" element={<AcademyOld />} />
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </BrowserRouter>
    </Wallet>
  )
}

export default App
