import React, { useEffect, useState, useRef } from 'react';
import { fetchGameData, playerTurn, displayHelp, whatNFT } from 'AcademyOld/helpers';
import {
  SELECTING_GAME,
  PLAYING_GAME,
} from 'AcademyOld/constants';
import {
  ActionButton,
  AppHeader,
  LoadingDisplay,
  SelectGame,
  DevTools,
  TerminalInput,
  TerminalOutput,
  TerminalPicture,
} from 'AcademyOld/components';
const PageMain = () => {
  const [appStatus, setAppStatus] = useState(SELECTING_GAME);
  const terminalInputRef = useRef(null);
  const soEof = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoading, setShowLoading] = useState('');
  const [gameInitted, setGameInitted] = useState(false);
  const [inputBuffer, setInputBuffer] = useState('');
  const [stdOutput, setStdOutput] = useState('Initializing...');
  const [gameData, setGameData] = useState(null);
  const [gameState, setGameState] = useState(null);

  const handleInputChange = (e) => {
    if (appStatus === PLAYING_GAME) {
      setInputBuffer(e.target.value.toUpperCase());
    }
  };
  const handleNext = (e) => {
    e.preventDefault();
    if (appStatus === PLAYING_GAME) {
      const prompt = 'NEXT';
      playerTurn(
        prompt,
        updateStdOutput,
        { ...gameState },
        gameData,
        setGameState,
        triggerLoadDisplay
      );
      setInputBuffer('');
    }
  };
  const handleBack = (e) => {
    e.preventDefault();
    if (appStatus === PLAYING_GAME) {
      const prompt = 'BACK';
      playerTurn(
        prompt,
        updateStdOutput,
        { ...gameState },
        gameData,
        setGameState,
        triggerLoadDisplay
      );
      setInputBuffer('');
    }
  };
  const resetGame = () => {
    setAppStatus(SELECTING_GAME);
    setStdOutput('');
    setInputBuffer('');
    setGameState(null);
  };
  const handleInputSubmit = (e) => {
    e.preventDefault();
    if (appStatus === PLAYING_GAME) {
      if (!gameState.gameActive || inputBuffer === 'QUIT') {
        resetGame();
        return null;
      }
      if (inputBuffer === '?') {
        displayHelp(updateStdOutput);
      } 
      if (inputBuffer === 'NFT?') {
        whatNFT(updateStdOutput);
      }else {
        playerTurn(
          inputBuffer,
          updateStdOutput,
          { ...gameState },
          gameData,
          setGameState,
          triggerLoadDisplay
        );
      }
      setInputBuffer('');
    }
  };
  const updateStdOutput = (s, mode) => {
    if (mode === 'append') {
      const maxLines = 50;
      const splitStdOutput = stdOutput.split('\n');
      const truncStdOutput = splitStdOutput
        .slice(Math.max(splitStdOutput.length - maxLines, 0))
        .join(String.fromCharCode(10));
      setStdOutput(`${truncStdOutput}${s}`);
    } else {
      setStdOutput(s);
    }
  };
  const handleGameChange = async (selectedGame) => {
    if (selectedGame && selectedGame.slug) {
      triggerLoadDisplay(false, 'loading');
      setGameInitted(false);
      await fetchGameData(selectedGame, setGameData, setGameState);
      setAppStatus(PLAYING_GAME);
      setIsLoading(false);
    }
  };
  const triggerLoadDisplay = (isFake, mode) => {
    document.body.classList.add('isLoading');
    setIsLoading(true);
    setShowLoading(mode);
    if (isFake) {
      setTimeout(() => {
        setIsLoading(false);
      }, 50);
    }
  };
  useEffect(() => {
    if (!isLoading) {
      const loadingTimer = setTimeout(() => {
        setShowLoading('');
        document.body.classList.remove('isLoading');
      }, 500);
      return () => clearTimeout(loadingTimer);
    }
  }, [isLoading]);
  useEffect(() => {
    soEof.current.scrollIntoView();
    terminalInputRef.current.focus();
  }, [stdOutput]);
  useEffect(() => {
    if (gameData && gameState && !gameInitted) {
      playerTurn(
        '',
        updateStdOutput,
        gameState,
        gameData,
        setGameState,
        triggerLoadDisplay
      );
      setGameInitted(true);
    }
  }, [gameData, gameState]);
  return (
    <div className="academy">
      <div className="page">
        <div className="pageFg">
          <AppHeader
            gameName={gameData?.headers.name}
            numTurns={gameState?.numTurns}
          />
          {appStatus === SELECTING_GAME && (
            <SelectGame setGame={handleGameChange} />
          )}
          <div
            className={`gameWrap ${appStatus === PLAYING_GAME ? 'active' : ''}`}
          >
            <div className='terminalAlign'>
              <TerminalOutput stdOutput={stdOutput} soEof={soEof} />
              {appStatus === PLAYING_GAME && (
              <TerminalPicture source={gameData.rooms[gameState.currentRoom].image} />
            )}
            </div>
            <button className ="commandButton" onClick={handleBack}>Back</button>
            {appStatus === PLAYING_GAME && (
              <ActionButton 
                action={gameData.rooms[gameState.currentRoom].links} 
                route={gameData.rooms[gameState.currentRoom].action} 
              />
            )}
            <button className ="commandButton" onClick={handleNext}>Next</button>
            <TerminalInput
              inputBuffer={inputBuffer}
              handleInputSubmit={handleInputSubmit}
              handleInputChange={handleInputChange}
              terminalInputRef={terminalInputRef}
            />
          </div>
        </div>
        {showLoading !== '' && <LoadingDisplay mode={showLoading} />}
      </div>
    </div>
  );
};
export default PageMain;
