import React, { useEffect, useRef, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import './styles.scss';

function Chat() {
  const [name, setName] = useState('Ichlas');
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef();

  const socketUrl = 'ws://localhost:8000';
  // const socketUrl = 'wss://solana-chatserver.herokuapp.com'

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log('Connected'),
    onClose: () => console.log('Disconnected'),
    onError: () => console.log('Error'),
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    // Exponential backoff
    reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const publicMessage = (messageString) => {
    if (messageString && messageString.trim().length !== 0) {
      let message = { type: 'public', sender: name, message: messageString };
      sendMessage(JSON.stringify(message));
      addMessage(message);
    }
  }

  const privateMessage = (messageString) => {
    if (messageString && messageString.trim().length !== 0) {
      let message = { type: 'private', sender: name, recipient: 'Ichlas', message: messageString };
      addMessage(message);
    }
  }

  const addMessage = (message) => {
    // Using a function as the argument resolves race conditions when many messages arrive at the same time
    setMessages(messages => [...messages, message]);
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages])

  useEffect(() => {
    if (lastMessage !== null) {
      addMessage(JSON.parse(lastMessage.data));
    }
  }, [lastMessage]);

  return (
    <div>
      <div className="fixed-chat">
        <div className="panel-chat">
          <div className="header-chat">
            <label htmlFor="name">
              Name:&nbsp;
              <input
                type="text"
                id={'name'}
                placeholder={'Enter your name...'}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </label>
          </div>
          <div className="body-chat">
            {messages.map((message, index) =>
              <ChatMessage
                key={index}
                message={message.message}
                name={message.sender}
              />,
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="message-chat">
            <ChatInput
              onSubmitMessage={messageString => publicMessage(messageString)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
